import "./skeleton.css";
import "./style.css";
import * as THREE from "three";

//orbit controls
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";

//Palette

const backgroundColor = new THREE.Color("LavenderBlush");

/**
 * Sizes
 */
const sizes = {
  width: window.innerWidth,
  height: window.innerHeight,
};

/**
 * Base
 */

// Canvas
const canvas = document.querySelector("canvas.webgl");

// Scene
const scene = new THREE.Scene();
scene.background = backgroundColor;

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(
  35,
  sizes.width / sizes.height,
  0.1,
  120
);
camera.position.set(-1.526809290138386, 2.933433136629435, 2.2502940751091676);
camera.rotation.set(
  -0.9164283978958037,
  -0.3916373693287119,
  -0.46170863682209606
);
camera.position.set(4, 6, 4);
camera.rotation.set(
  -0.9164283978958037,
  -0.3916373693287119,
  -0.46170863682209606
);
camera.lookAt(0, 0, 0);
// const controls = new OrbitControls(camera, canvas);

scene.add(camera);

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
});
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
/**
 * Sizes
 */
// Window resizing
window.addEventListener("resize", () => {
  // Update sizes
  sizes.width = window.innerWidth;
  sizes.height = window.innerHeight;

  // Update camera
  camera.aspect = sizes.width / sizes.height;
  camera.updateProjectionMatrix();

  // Update renderer
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
});

const group = new THREE.Group();
const geometry = new THREE.TorusKnotGeometry(0.15, 0.06, 64, 64, 2, 3);
const matcapTexture = new THREE.TextureLoader().load("matcap6.png", () => {
  for (let i = 0; i < 100; i++) {
    //box geometry
    // const geometry = new THREE.BoxGeometry(0.2, 0.2, 0.2);

    // const material = new THREE.MeshBasicMaterial({
    //   color: 0x000000,
    //   wireframe: true,
    //   opacity: 0.1,
    // });
    //matcapTexture from matcapTexture.jpg

    const material = new THREE.MeshMatcapMaterial({
      matcap: matcapTexture,
    });
    const mesh = new THREE.Mesh(geometry, material);
    mesh.position.x = (Math.random() - 0.5) * 10;
    mesh.position.y = (Math.random() - 0.5) * 10;
    mesh.position.z = (Math.random() - 0.5) * 10;
    //rotation random
    mesh.rotation.x = Math.random() * Math.PI;
    mesh.rotation.y = Math.random() * Math.PI;
    mesh.rotation.z = Math.random() * Math.PI;
    // rotate so the knot faces the center given its position

    const scale = 0.5;
    mesh.scale.set(scale, scale, scale);

    group.add(mesh);
  }
});

scene.add(group);
/**
 * Animate
 */
const clock = new THREE.Clock();
const tick = () => {
  let e = clock.getElapsedTime();
  // iterate through each mesh in the scene and look at 0,0,0
  group.children.forEach((child) => {
    // child.lookAt(0, 0, 0);
    child.lookAt(camera.position);
  });
  group.rotation.x += 0.0007;
  group.rotation.y += 0.00001;
  renderer.render(scene, camera);
  window.requestAnimationFrame(tick);
};

tick();
